var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          options: _vm.currentOptions,
          label: _vm.optionsLabel,
          reduce: _vm.reduce,
          selectable: function (x) {
            return x.status == "Active"
          },
          disabled: _vm.disabled,
          placeholder: _vm.placeholdrField,
        },
        on: { "option:selecting": _vm.optionSelected },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (_, slot) {
              return {
                key: slot,
                fn: function (props) {
                  return [_vm._t(slot, null, null, props)]
                },
              }
            }),
          ],
          null,
          true
        ),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }