






























import {
  Component,
  ModelSync,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { required } from "vee-validate/dist/rules";
import _ from "lodash";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    
    BFormGroup,
    BFormInput,
  },
})
export default class InputOverride extends Vue {
  @ModelSync("inputData", "input")
  inputField!: Object | null;

  @Prop()
  label!: String;

  @Prop()
  type!: String;

  @PropSync("overrideData")
  overrideField!: Object;

  editButtonAllow = true;

  @Watch("inputField")
  inputFieldWatch() {
    console.log(this.inputField)
    if (_.isEmpty(this.inputField) == false||_.isNumber(this.inputField)) {
      console.log(this.inputField)
      this.editButtonAllow = false;
    }
  }

  @Ref("input") readonly refs_input!:any;

  onEditClick() {
    if (this.editButtonAllow == false) {
      this.inputField = null;
      
    }else{
      console.log(this.refs_input)
      this.refs_input.focus()
    }
    this.editButtonAllow = !this.editButtonAllow;
  }
}
