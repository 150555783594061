var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [
          _c("validation-provider", {
            attrs: {
              name: _vm.label,
              rules: !_vm.editButtonAllow ? "required" : "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c("single-select", {
                      attrs: {
                        relationField: _vm.relationField,
                        idsToDisable: _vm.idsToDisable,
                        optionsLabel: _vm.optionsLabel,
                        optionsRepo: _vm.optionsRepo,
                        placeholder: _vm.overrideData,
                        disabled: _vm.editButtonAllow,
                        isOptionsFromRepo: _vm.isOptionsFromRepo,
                        options: _vm.options,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "open-indicator",
                            fn: function (ref) {
                              var attributes = ref.attributes
                              return [
                                _c(
                                  "b-input-group-addon",
                                  { attrs: { "is-text": "" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass:
                                        "cursor-pointer text-secondary",
                                      attrs: { icon: "EditIcon" },
                                      on: { click: _vm.onEditClick },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.formData,
                        callback: function ($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData",
                      },
                    }),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }